<template>
  <div>
    <section class="section position-relative popUpFather">
      <div class="row">
        <div class="col-12">
          <div class="bg-white p-2">
            <b-tabs
              :value="$store.state.tabs.activeTab"
              @changed="activeTab = $store.state.tabs.activeTab"
              @activate-tab="
                (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
              "
              class="tab-solid tab-solid-primary mt-3"
            >
              <b-tab
                title="Les concepts métiers"
                v-if="isAdmin || isSuper || isManager || isPo || isArchitect"
              >
                <div class="mt-3">
                  <div
                    class="
                      col-12
                      p-0
                      d-flex
                      flex-wrap
                      justify-content-between
                      flex-wrap
                      mb-2
                    "
                  >
                    <div class="my-auto mr-2">
                      <h4 class="card-title my-auto">Liste des concepts</h4>
                    </div>
                    <div class="col-3 p-0 ml-auto mr-1 my-auto">
                      <b-form-input
                        v-model="searchQuery.name"
                        placeholder="Recherche..."
                      ></b-form-input>
                    </div>
                    <CustomAddButton
                      v-if="isAdmin || isSuper || isArchitect"
                      @click="$router.push({ name: 'add-concept' })"
                      text="Nouveau concept"
                    />
                  </div>
                  <hr class="mt-0 bg-secondary" />

                  <concept-table
                    :items="filteredConcepts"
                    :loadingData="loadingData"
                    @showItem="$router.push({ name: 'show-concept' })"
                    @editItem="$router.push({ name: 'edit-concept' })"
                  />
                </div>
              </b-tab>
              <b-tab
                title="Les objets métiers"
                v-if="
                  isAdmin ||
                  isSuper ||
                  isManager ||
                  isPo ||
                  isArchitect ||
                  isCollaborator
                "
              >
                <div class="mt-3">
                  <div
                    class="
                      col-12
                      p-0
                      d-flex
                      flex-wrap
                      justify-content-between
                      flex-wrap
                      mb-2
                    "
                  >
                    <div class="my-auto mr-2">
                      <h4 class="card-title my-auto">
                        Liste des objects métier
                      </h4>
                    </div>
                    <div class="col-3 p-0 ml-auto mr-1 my-auto">
                      <b-form-input
                        v-model="objetSearchQuery.name"
                        placeholder="Recherche..."
                      ></b-form-input>
                    </div>
                    <CustomAddButton
                      v-if="isAdmin || isSuper || isArchitect"
                      @click="$router.push({ name: 'add-business-object' })"
                      text="Nouvel objet métier"
                    />
                  </div>
                  <hr class="mt-0 bg-secondary" />

                  <business-object-table
                    :items="filteredObjets"
                    :loadingData="loadingData"
                    @showItem="$router.push({ name: 'show-business-object' })"
                    @editItem="$router.push({ name: 'edit-business-object' })"
                  />
                </div>
              </b-tab>
              <b-tab title="Vue graphique">
                <div class="row col-12 mx-auto">
                  <div class="col-12 col-sm-3">
                    <!-- <b-form-input placeholder="Recherche.."></b-form-input> -->
                  </div>
                  <div class="col-12 d-flex justify-content-end p-0 mb-3">
                    <CustomAddButton
                      v-if="isAdmin || isSuper || isArchitect"
                      @click="$router.push({ name: 'add-business-object' })"
                      text="Nouvel objet métier"
                    />
                  </div>
                  <div class="w-100">
                    <div class="shdow p-4">
                      <div class="row">
                        <div
                          class="col-12 col-md-4 p-2"
                          v-for="(concept, index) in CONCEPTS"
                          :key="index"
                        >
                          <div class="shdow h-100">
                            <div
                              class="
                                d-flex
                                flex-wrap
                                justify-content-between
                                align-items-center
                                mb-3
                              "
                            >
                              <p class="h4 mb-0">{{ concept.name }}</p>
                              <view-actions
                                @cancel="deleteConceptItem(concept.id)"
                                :actions="
                                  isAdmin || isSuper || isArchitect
                                    ? ['cancel']
                                    : []
                                "
                              />
                            </div>
                            <div class="d-flex flex-wrap gap-1">
                              <div
                                v-for="(objet, oIndex) in concept.objets"
                                :key="oIndex"
                                class="business-object shdow p-2 my-3 mx-1"
                              >
                                <view-actions
                                  class="business-object-actions"
                                  :actions="
                                    isAdmin || isSuper || isArchitect
                                      ? ['cancel']
                                      : []
                                  "
                                  size="20px"
                                  :isLink="true"
                                  @cancel="deleteObjetItem(objet.id)"
                                  @show="
                                    $router.push({
                                      name: 'show-business-object',
                                      params: {
                                        slug: objet.slug || 'abcde',
                                        id: objet.id,
                                      },
                                    })
                                  "
                                />
                                <span>{{ objet.name }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BusinessObjectTable from "./components/BusinessObjectTable.vue";
import ConceptTable from "./components/ConceptTable.vue";
import ViewActions from "./components/ViewActions.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";

export default {
  components: {
    ConceptTable,
    BusinessObjectTable,
    ViewActions,
    CustomAddButton,
  },
  data: () => ({
    showGeoMap: false,
    loadingData: true,
    searchQuery: {
      name: "",
    },
    objetSearchQuery: {
      name: "",
    },
  }),
  methods: {
    deleteConceptItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("concept/deleteConcept", id).then(() => {
            this.$store.dispatch("objet/fetchAllObjets");
            this.$store.dispatch("concept/fetchAllConcepts").then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
          });
        }
      });
    },
    deleteObjetItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("objet/deleteObjet", id).then(() => {
            this.$store.dispatch("concept/fetchAllConcepts");
            this.$store.dispatch("objet/fetchAllObjets").then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
          });
        }
      });
    },
  },
  created() {
    this.$store.dispatch("concept/fetchAllConcepts").then(() => {
      this.loadingData = false;
    });
    this.$store.dispatch("objet/fetchAllObjets").then(() => {
      this.loadingData = false;
    });
  },
  computed: {
    ...mapGetters("concept", ["CONCEPTS"]),
    ...mapGetters("objet", ["OBJETS"]),
    ...mapGetters([
      "isAdmin",
      "isSuper",
      "isManager",
      "isArchitect",
      "isPo",
      "isCollaborator",
    ]),
    filteredConcepts() {
      return this.CONCEPTS.filter(
        (concept) =>
          concept.name
            .toLowerCase()
            .indexOf(this.searchQuery.name.toLowerCase()) > -1
      );
    },
    filteredObjets() {
      return this.OBJETS.filter(
        (objet) =>
          objet.name
            .toLowerCase()
            .indexOf(this.objetSearchQuery.name.toLowerCase()) > -1
      );
    },
  },
};
</script>