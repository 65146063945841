<template>
  <div>
    <b-table
      responsive
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loadingData"
      show-empty
      bordered
      striped
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(name)="data">
        <b-link
          :to="{
            name: 'show-concept',
            params: { slug: data.item.slug || 'abcde', id: data.item.id },
          }"
        >
          {{ data.value }}
        </b-link>
      </template>
      <template v-slot:cell(description)="data">
        <span> {{ data.value || "-" }} </span>
      </template>
      <template #cell(actions)="data">
        <table-actions
          :actions="isAdmin || isSuper || isArchitect ? ['delete'] : []"
          :editMode="data.item.hasOwnProperty('editMode')"
          @showItem="
            $router.push({
              name: 'show-concept',
              params: { slug: data.item.slug || 'abcde', id: data.item.id },
            })
          "
          @editItem="$emit('editItem')"
          @deleteItem="deleteItem(data.item.id)"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";

import { mapGetters } from "vuex";

export default {
  components: { TableActions },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loadingData: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    sortBy: "name",
    perPage: 10,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,

    fields: [
      { key: "name", label: "Concept métier", sortable: true },
      { key: "description", label: "Description", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
  }),
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
    rows() {
      return this.items.length;
      // return 3;
    },
  },
  methods: {
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("concept/deleteConcept", id).then(() => {
            this.$store.dispatch("concept/fetchAllConcepts").then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
          });
        }
      });
    },
  },
};
</script>

<style></style>
