var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table',{attrs:{"responsive":"","items":_vm.items,"per-page":_vm.perPage,"current-page":_vm.currentPage,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.loadingData,"show-empty":"","bordered":"","striped":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-2"}),_c('strong',[_vm._v("Chargement...")])],1)]},proxy:true},{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(name)",fn:function(data){return [_c('b-link',{attrs:{"to":{
          name: 'show-concept',
          params: { slug: data.item.slug || 'abcde', id: data.item.id },
        }}},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"cell(description)",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.value || "-")+" ")])]}},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":_vm.isAdmin || _vm.isSuper || _vm.isArchitect ? ['delete'] : [],"editMode":data.item.hasOwnProperty('editMode')},on:{"showItem":function($event){return _vm.$router.push({
            name: 'show-concept',
            params: { slug: data.item.slug || 'abcde', id: data.item.id },
          })},"editItem":function($event){return _vm.$emit('editItem')},"deleteItem":function($event){return _vm.deleteItem(data.item.id)}}})]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }