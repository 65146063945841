<template>
  <div>
    <div v-if="!isLink" class="d-flex justify-content-end">
      <b-button
        v-if="actions.includes('add')"
        variant="success"
        pill
        size="sm"
        :style="{ height: size ? size : '30px', width: size ? size : '30px' }"
        class="d-flex align-items-center justify-content-center"
        ><i class="mdi mdi-plus"></i
      ></b-button>
      <b-button
        v-if="actions.includes('cancel')"
        variant="danger"
        pill
        size="sm"
        @click="$emit('cancel')"
        :style="{ height: size ? size : '30px', width: size ? size : '30px' }"
        class="d-flex align-items-center justify-content-center"
        ><i class="mdi mdi-minus"></i
      ></b-button>
      <b-button
        v-if="actions.includes('edit')"
        class="d-flex align-items-center justify-content-center"
        variant="warning"
        pill
        size="sm"
        :style="{ height: size ? size : '30px', width: size ? size : '30px' }"
        ><i class="mdi mdi-file-document-edit"></i
      ></b-button>
    </div>
    <div v-else class="d-flex justify-content-end">
      <div
        href="#"
        v-if="actions.includes('add')"
        :style="{
          height: size ? size : '15px',
          width: size ? size : '15px',
          cursor: 'pointer',
        }"
        class="d-flex align-items-center justify-content-center text-white bg-success rounded-circle mr-1"
      >
        <i class="mdi mdi-plus" style="margin-top: 2px"></i>
      </div>
      <div
        @click="$emit('cancel')"
        v-if="actions.includes('cancel')"
        :style="{
          height: size ? size : '15px',
          width: size ? size : '15px',
          cursor: 'pointer',
        }"
        class="d-flex align-items-center justify-content-center text-white bg-danger rounded-circle mr-1"
      >
        <i class="mdi mdi-minus" style="margin-top: 2px"></i>
      </div>
      <div
        @click="$emit('show')"
        v-if="actions.includes('edit')"
        class="d-flex align-items-center justify-content-center text-white bg-warning rounded-circle mr-1"
        :style="{
          height: size ? size : '15px',
          width: size ? size : '15px',
          cursor: 'pointer',
        }"
      >
        <i class="mdi mdi-file-document-edit" style="margin-top: 2px"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    actions: Array,
    size: String,
    isLink: Boolean,
  },
};
</script>

<style></style>
